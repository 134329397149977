<template>
  <div class="mini_game_bet">
    <div class="mini_game">
      <div class="m1">
        <div class="mini_fram">
          <leisure-game-ifram-view>
            <iframe id="bet365-gamefram"  scrolling="no" frameborder="0"
                    src="https://vet3.net/video?q=high&player=3&autostart=true&title=Superleague"></iframe>
          </leisure-game-ifram-view>
        </div>
      </div>
      <div class="m2">
        <div class="game_tab">
          <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
          <leisure-timer-comp
                  ref="ltimer"
                  :leisure-game-info="leisureGameInfo"
                  @timerStop="timerStop" @refreshTimer="refreshTimer">
            <span slot="title" class="text-orange"><i class="fa fa-star"></i>{{i18nConst.GAME['SUPERLEAGUE'][$store.state.country]}}</span>
          </leisure-timer-comp>

          <!--선택부분-->
          <div class="mini_bet_box">
            <div class="b_s_match_box">
              <div class="team_name">
                <div class="home">
                  <span class="" v-if="$store.state.country === i18nConst.KR">{{spGame.homeTeamName}}</span>
                  <span class="" v-if="$store.state.country === i18nConst.EN">{{spGame.ennamehome}}</span>
                  <span class="" v-if="$store.state.country === i18nConst.JP">{{spGame.jpnamehome}}</span>
                  <span class="" v-if="$store.state.country === i18nConst.FRA">{{spGame.franamehome}}</span>
                  <span class="" v-if="$store.state.country === i18nConst.SPA">{{spGame.spanamehome}}</span>
                </div>
                <div class="vs"><span>VS</span></div>
                <div class="away">
                  <span class="" v-if="$store.state.country === i18nConst.KR">{{spGame.awayTeamName}}</span>
                  <span class="" v-if="$store.state.country === i18nConst.EN">{{spGame.ennameaway}}</span>
                  <span class="" v-if="$store.state.country === i18nConst.JP">{{spGame.jpnameaway}}</span>
                  <span class="" v-if="$store.state.country === i18nConst.FRA">{{spGame.franameaway}}</span>
                  <span class="" v-if="$store.state.country === i18nConst.SPA">{{spGame.spanameaway}}</span>
                </div>
              </div>
              <div class="mg">
                <div class="mg_btn b3" :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_SUPERLEAGUE_1x2 && selectedInfo.selectedWay === 1}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_SUPERLEAGUE_1x2, spGame.id,1, spGame.homeTeamOdds, spConfig, '승')">
                  <div class="rs">
                    <span class="n text_color01">{{i18nConst.GAME['HOME_WIN'][$store.state.country]}}</span>
                    <span class="b">{{ spGame.homeTeamOdds}}</span>
                  </div>
                </div>
                <div class="mg_btn b3" :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_SUPERLEAGUE_1x2 && selectedInfo.selectedWay === 2}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_SUPERLEAGUE_1x2, spGame.id,2,  spGame.drawOdds, spConfig, '무')">
                  <div class="rs">
                    <span class="n text_color02">{{i18nConst.COMMON['TIE'][$store.state.country]}}</span>
                    <span class="b">{{ spGame.drawOdds}}</span>
                  </div>
                </div>
                <div class="mg_btn b3" :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_SUPERLEAGUE_1x2 && selectedInfo.selectedWay === 3}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_SUPERLEAGUE_1x2, spGame.id,3,  spGame.awayTeamOdds, spConfig, '패')">
                  <div class="rs">
                    <span class="n text_color02">{{i18nConst.GAME['AWAY_WIN'][$store.state.country]}}</span>
                    <span class="b">{{ spGame.awayTeamOdds}}</span>
                  </div>
                </div>
              </div>
              <div class="mg">
                <div class="mg_btn b2" :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_SUPERLEAGUE_OVERUNDER && selectedInfo.selectedWay === 1}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_SUPERLEAGUE_OVERUNDER,souGame.id,1, souGame.homeTeamOdds, souConfig, '오버')">
                  <div class="rs">
                    <span class="n text_color01">{{i18nConst.COMMON['OVER'][$store.state.country]}}({{ souGame.overunderVal}})</span>
                    <span class="b">{{ souGame.homeTeamOdds}}</span>
                  </div>
                </div>
                <div class="mg_btn b2" :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_SUPERLEAGUE_OVERUNDER && selectedInfo.selectedWay === 3}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_SUPERLEAGUE_OVERUNDER,souGame.id,3,  souGame.awayTeamOdds, souConfig, '언더')">
                  <div class="rs">
                    <span class="n text_color02">{{i18nConst.COMMON['UNDER'][$store.state.country]}}({{ souGame.overunderVal}})</span>
                    <span class="b">{{ souGame.awayTeamOdds}}</span>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <!--파워볼 베팅카트-->
          <leisure-bet-cart-comp
                  ref="betCart" :kind="kind"
                  :selected-info="selectedInfo">
            슈퍼리그
          </leisure-bet-cart-comp>


        </div>
      </div>
      <div class="m3">
        <!--최근 배팅내역-->
        <leisure-lately-bet-list-comp
                ref="latelyBetList"
                :kind="kind"></leisure-lately-bet-list-comp>

      </div>


    </div>
  </div>
</template>

<script>
  import SubTitle from "../../../components/SubTitle";
  import LeisureTimerComp from "../../../components/leisuregame/LeisureTimerComp";
  import LeisureBetCartComp from "../../../components/leisuregame/LeisureBetCartComp";
  import leisureConst from "../../../common/leisureConst";
  import sportsConst from "../../../common/sportsConst";
  import LeisureBetMasker from "../../../components/leisuregame/LeisureBetMasker";
  import {leisureGameMixin} from "../../../common/mixin";
  import {getLeisureGameAttributeConfig} from "../../../network/leisureRequest";
  import LeisureLatelyBetListComp from "../../../components/leisuregame/LeisureLatelyBetListComp";
  import LeisureGameView from "../../../components/leisuregame/LeisureGameView";
  import LeisureGameMathBoxComp from "../../../components/leisuregame/LeisureGameMathBoxComp";
  import LeisureGameLinks from "../LeisureGameLinks";
  import LeisureB365SoccerLinks from "../LeisureB365SoccerLinks";
  import LeisureGameIframView from "../LeisureGameIframView";
  import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "../../../store/mutation-types";
  import i18nConst from "../../../common/i18nConst";

  export default {

    name: "Bet365SoccerSuperLeague",
    mixins: [leisureGameMixin],
    components: {
      LeisureGameIframView,
      LeisureB365SoccerLinks,
      LeisureGameLinks,
      LeisureGameMathBoxComp,
      LeisureGameView,
      LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp, SubTitle},
    data() {
      return {
        i18nConst:i18nConst,
        kind: leisureConst.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE,
      }
    },

    methods: {
      initLeisureGameArttributeConfig() {
        getLeisureGameAttributeConfig().then(res => {
          if (res.data.success) {
            this.attrConfig = res.data.data
            //배당설정
            this.spConfig = this.attrConfig.find(attr => {
              return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_SUPERLEAGUE_1x2)
            })

            this.souConfig = this.attrConfig.find(attr => {
              return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_SUPERLEAGUE_OVERUNDER)
            })
          }
        })
      }
    },
    created() {
      this.initKindConfig(this.kind)
      this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP,leisureConst.LEISURE_COMP_BET365);
      this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID,this.kind);
    },
  }
</script>


<style scoped>
</style>